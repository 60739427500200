import { default as about_45us9kiw2OpfgnMeta } from "/opt/build/repo/pages/about-us.vue?macro=true";
import { default as indexmFsYN4t0XfMeta } from "/opt/build/repo/pages/blog/index.vue?macro=true";
import { default as _91slug_93CNQCnZdu7CMeta } from "/opt/build/repo/pages/blog/post/[slug].vue?macro=true";
import { default as _91slug_93fIpShXNSxJMeta } from "/opt/build/repo/pages/body-type/[slug].vue?macro=true";
import { default as how_45to_45apply_45for_45an_45operators_45licenceHdDvqjjtIiMeta } from "/opt/build/repo/pages/compliance/how-to-apply-for-an-operators-licence.vue?macro=true";
import { default as contactNfojXvLlAkMeta } from "/opt/build/repo/pages/contact.vue?macro=true";
import { default as _91slug_93dEMTZNnNjMMeta } from "/opt/build/repo/pages/crane-manufacturers/[slug].vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as main_45searchCrqG5OHAF1Meta } from "/opt/build/repo/pages/main-search.vue?macro=true";
import { default as _91slug_93QIeSVH8tUqMeta } from "/opt/build/repo/pages/manufacturers/[slug].vue?macro=true";
import { default as indexknxUuZx6J5Meta } from "/opt/build/repo/pages/marketing/vehicle/index.vue?macro=true";
import { default as modern_45slaveryZa6ibTHNiWMeta } from "/opt/build/repo/pages/modern-slavery.vue?macro=true";
import { default as _91slug_93r6vRNDvXJcMeta } from "/opt/build/repo/pages/more-information/[slug].vue?macro=true";
import { default as _91service_93MXyBaI7GoxMeta } from "/opt/build/repo/pages/our-services/[service].vue?macro=true";
import { default as index9A5giULScrMeta } from "/opt/build/repo/pages/our-services/index.vue?macro=true";
import { default as how_45to_45get_45an_45operators_45licencesXqf9pwBs6Meta } from "/opt/build/repo/pages/pages/how-to-get-an-operators-licence.vue?macro=true";
import { default as privacy_45policyQXTlhFuZ55Meta } from "/opt/build/repo/pages/privacy-policy.vue?macro=true";
import { default as quick_45quote7i8xCgXdhuMeta } from "/opt/build/repo/pages/quote/quick-quote.vue?macro=true";
import { default as indexMhEhZj5uLaMeta } from "/opt/build/repo/pages/shortlist/[[token]]/index.vue?macro=true";
import { default as terms_45of_45useLJJIK8dQt6Meta } from "/opt/build/repo/pages/terms-of-use.vue?macro=true";
import { default as indexI4NRwJNw5aMeta } from "/opt/build/repo/pages/truck-finance/index.vue?macro=true";
import { default as _91slug_93pV4Y6duPX8Meta } from "/opt/build/repo/pages/vehicle-view/[slug].vue?macro=true";
import { default as _91slug_93jB44oU6p3wMeta } from "/opt/build/repo/pages/vehicle/[manufacturer]/[slug].vue?macro=true";
import { default as _91slug_9301B8tE5D62Meta } from "/opt/build/repo/pages/weights/[slug].vue?macro=true";
export default [
  {
    name: about_45us9kiw2OpfgnMeta?.name ?? "about-us",
    path: about_45us9kiw2OpfgnMeta?.path ?? "/about-us",
    meta: about_45us9kiw2OpfgnMeta || {},
    alias: about_45us9kiw2OpfgnMeta?.alias || [],
    redirect: about_45us9kiw2OpfgnMeta?.redirect,
    component: () => import("/opt/build/repo/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: indexmFsYN4t0XfMeta?.name ?? "blog",
    path: indexmFsYN4t0XfMeta?.path ?? "/blog",
    meta: indexmFsYN4t0XfMeta || {},
    alias: indexmFsYN4t0XfMeta?.alias || [],
    redirect: indexmFsYN4t0XfMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CNQCnZdu7CMeta?.name ?? "blog-post-slug",
    path: _91slug_93CNQCnZdu7CMeta?.path ?? "/blog/post/:slug()",
    meta: _91slug_93CNQCnZdu7CMeta || {},
    alias: _91slug_93CNQCnZdu7CMeta?.alias || [],
    redirect: _91slug_93CNQCnZdu7CMeta?.redirect,
    component: () => import("/opt/build/repo/pages/blog/post/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93fIpShXNSxJMeta?.name ?? "body-type-slug",
    path: _91slug_93fIpShXNSxJMeta?.path ?? "/body-type/:slug()",
    meta: _91slug_93fIpShXNSxJMeta || {},
    alias: _91slug_93fIpShXNSxJMeta?.alias || [],
    redirect: _91slug_93fIpShXNSxJMeta?.redirect,
    component: () => import("/opt/build/repo/pages/body-type/[slug].vue").then(m => m.default || m)
  },
  {
    name: how_45to_45apply_45for_45an_45operators_45licenceHdDvqjjtIiMeta?.name ?? "compliance-how-to-apply-for-an-operators-licence",
    path: how_45to_45apply_45for_45an_45operators_45licenceHdDvqjjtIiMeta?.path ?? "/compliance/how-to-apply-for-an-operators-licence",
    meta: how_45to_45apply_45for_45an_45operators_45licenceHdDvqjjtIiMeta || {},
    alias: how_45to_45apply_45for_45an_45operators_45licenceHdDvqjjtIiMeta?.alias || [],
    redirect: how_45to_45apply_45for_45an_45operators_45licenceHdDvqjjtIiMeta?.redirect,
    component: () => import("/opt/build/repo/pages/compliance/how-to-apply-for-an-operators-licence.vue").then(m => m.default || m)
  },
  {
    name: contactNfojXvLlAkMeta?.name ?? "contact",
    path: contactNfojXvLlAkMeta?.path ?? "/contact",
    meta: contactNfojXvLlAkMeta || {},
    alias: contactNfojXvLlAkMeta?.alias || [],
    redirect: contactNfojXvLlAkMeta?.redirect,
    component: () => import("/opt/build/repo/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dEMTZNnNjMMeta?.name ?? "crane-manufacturers-slug",
    path: _91slug_93dEMTZNnNjMMeta?.path ?? "/crane-manufacturers/:slug()",
    meta: _91slug_93dEMTZNnNjMMeta || {},
    alias: _91slug_93dEMTZNnNjMMeta?.alias || [],
    redirect: _91slug_93dEMTZNnNjMMeta?.redirect,
    component: () => import("/opt/build/repo/pages/crane-manufacturers/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: main_45searchCrqG5OHAF1Meta?.name ?? "main-search",
    path: main_45searchCrqG5OHAF1Meta?.path ?? "/main-search",
    meta: main_45searchCrqG5OHAF1Meta || {},
    alias: main_45searchCrqG5OHAF1Meta?.alias || [],
    redirect: main_45searchCrqG5OHAF1Meta?.redirect,
    component: () => import("/opt/build/repo/pages/main-search.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QIeSVH8tUqMeta?.name ?? "manufacturers-slug",
    path: _91slug_93QIeSVH8tUqMeta?.path ?? "/manufacturers/:slug()",
    meta: _91slug_93QIeSVH8tUqMeta || {},
    alias: _91slug_93QIeSVH8tUqMeta?.alias || [],
    redirect: _91slug_93QIeSVH8tUqMeta?.redirect,
    component: () => import("/opt/build/repo/pages/manufacturers/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexknxUuZx6J5Meta?.name ?? "marketing-vehicle",
    path: indexknxUuZx6J5Meta?.path ?? "/marketing/vehicle",
    meta: indexknxUuZx6J5Meta || {},
    alias: indexknxUuZx6J5Meta?.alias || [],
    redirect: indexknxUuZx6J5Meta?.redirect,
    component: () => import("/opt/build/repo/pages/marketing/vehicle/index.vue").then(m => m.default || m)
  },
  {
    name: modern_45slaveryZa6ibTHNiWMeta?.name ?? "modern-slavery",
    path: modern_45slaveryZa6ibTHNiWMeta?.path ?? "/modern-slavery",
    meta: modern_45slaveryZa6ibTHNiWMeta || {},
    alias: modern_45slaveryZa6ibTHNiWMeta?.alias || [],
    redirect: modern_45slaveryZa6ibTHNiWMeta?.redirect,
    component: () => import("/opt/build/repo/pages/modern-slavery.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93r6vRNDvXJcMeta?.name ?? "more-information-slug",
    path: _91slug_93r6vRNDvXJcMeta?.path ?? "/more-information/:slug()",
    meta: _91slug_93r6vRNDvXJcMeta || {},
    alias: _91slug_93r6vRNDvXJcMeta?.alias || [],
    redirect: _91slug_93r6vRNDvXJcMeta?.redirect,
    component: () => import("/opt/build/repo/pages/more-information/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91service_93MXyBaI7GoxMeta?.name ?? "our-services-service",
    path: _91service_93MXyBaI7GoxMeta?.path ?? "/our-services/:service()",
    meta: _91service_93MXyBaI7GoxMeta || {},
    alias: _91service_93MXyBaI7GoxMeta?.alias || [],
    redirect: _91service_93MXyBaI7GoxMeta?.redirect,
    component: () => import("/opt/build/repo/pages/our-services/[service].vue").then(m => m.default || m)
  },
  {
    name: index9A5giULScrMeta?.name ?? "our-services",
    path: index9A5giULScrMeta?.path ?? "/our-services",
    meta: index9A5giULScrMeta || {},
    alias: index9A5giULScrMeta?.alias || [],
    redirect: index9A5giULScrMeta?.redirect,
    component: () => import("/opt/build/repo/pages/our-services/index.vue").then(m => m.default || m)
  },
  {
    name: how_45to_45get_45an_45operators_45licencesXqf9pwBs6Meta?.name ?? "pages-how-to-get-an-operators-licence",
    path: how_45to_45get_45an_45operators_45licencesXqf9pwBs6Meta?.path ?? "/pages/how-to-get-an-operators-licence",
    meta: how_45to_45get_45an_45operators_45licencesXqf9pwBs6Meta || {},
    alias: how_45to_45get_45an_45operators_45licencesXqf9pwBs6Meta?.alias || [],
    redirect: how_45to_45get_45an_45operators_45licencesXqf9pwBs6Meta?.redirect,
    component: () => import("/opt/build/repo/pages/pages/how-to-get-an-operators-licence.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyQXTlhFuZ55Meta?.name ?? "privacy-policy",
    path: privacy_45policyQXTlhFuZ55Meta?.path ?? "/privacy-policy",
    meta: privacy_45policyQXTlhFuZ55Meta || {},
    alias: privacy_45policyQXTlhFuZ55Meta?.alias || [],
    redirect: privacy_45policyQXTlhFuZ55Meta?.redirect,
    component: () => import("/opt/build/repo/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: quick_45quote7i8xCgXdhuMeta?.name ?? "quote-quick-quote",
    path: quick_45quote7i8xCgXdhuMeta?.path ?? "/quote/quick-quote",
    meta: quick_45quote7i8xCgXdhuMeta || {},
    alias: quick_45quote7i8xCgXdhuMeta?.alias || [],
    redirect: quick_45quote7i8xCgXdhuMeta?.redirect,
    component: () => import("/opt/build/repo/pages/quote/quick-quote.vue").then(m => m.default || m)
  },
  {
    name: indexMhEhZj5uLaMeta?.name ?? "shortlist-token",
    path: indexMhEhZj5uLaMeta?.path ?? "/shortlist/:token?",
    meta: indexMhEhZj5uLaMeta || {},
    alias: indexMhEhZj5uLaMeta?.alias || [],
    redirect: indexMhEhZj5uLaMeta?.redirect,
    component: () => import("/opt/build/repo/pages/shortlist/[[token]]/index.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45useLJJIK8dQt6Meta?.name ?? "terms-of-use",
    path: terms_45of_45useLJJIK8dQt6Meta?.path ?? "/terms-of-use",
    meta: terms_45of_45useLJJIK8dQt6Meta || {},
    alias: terms_45of_45useLJJIK8dQt6Meta?.alias || [],
    redirect: terms_45of_45useLJJIK8dQt6Meta?.redirect,
    component: () => import("/opt/build/repo/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: indexI4NRwJNw5aMeta?.name ?? "truck-finance",
    path: indexI4NRwJNw5aMeta?.path ?? "/truck-finance",
    meta: indexI4NRwJNw5aMeta || {},
    alias: indexI4NRwJNw5aMeta?.alias || [],
    redirect: indexI4NRwJNw5aMeta?.redirect,
    component: () => import("/opt/build/repo/pages/truck-finance/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93pV4Y6duPX8Meta?.name ?? "vehicle-view-slug",
    path: _91slug_93pV4Y6duPX8Meta?.path ?? "/vehicle-view/:slug()",
    meta: _91slug_93pV4Y6duPX8Meta || {},
    alias: _91slug_93pV4Y6duPX8Meta?.alias || [],
    redirect: _91slug_93pV4Y6duPX8Meta?.redirect,
    component: () => import("/opt/build/repo/pages/vehicle-view/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jB44oU6p3wMeta?.name ?? "vehicle-manufacturer-slug",
    path: _91slug_93jB44oU6p3wMeta?.path ?? "/vehicle/:manufacturer()/:slug()",
    meta: _91slug_93jB44oU6p3wMeta || {},
    alias: _91slug_93jB44oU6p3wMeta?.alias || [],
    redirect: _91slug_93jB44oU6p3wMeta?.redirect,
    component: () => import("/opt/build/repo/pages/vehicle/[manufacturer]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_9301B8tE5D62Meta?.name ?? "weights-slug",
    path: _91slug_9301B8tE5D62Meta?.path ?? "/weights/:slug()",
    meta: _91slug_9301B8tE5D62Meta || {},
    alias: _91slug_9301B8tE5D62Meta?.alias || [],
    redirect: _91slug_9301B8tE5D62Meta?.redirect,
    component: () => import("/opt/build/repo/pages/weights/[slug].vue").then(m => m.default || m)
  }
]